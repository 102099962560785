<template>
  <div class="content-top-wapper w-100">
    <div class="d-flex flex-column">
      <div :class="comName ? comName : 'no-angle'" class="content-top d-flex jc-around">
        <span v-text="title" class="content-top-title"></span>
        <div class="content-top-right">
          <template v-if="topRadioProps && topRadioProps.options.length">
            <Radio :radioProps="topRadioProps" @radioChange="topRadioChange" />
          </template>
          <template v-if="topOptionsProps && topOptionsProps.selects.length">
            <el-select
              v-for="(select, index) in topOptionsProps.selects"
              v-model="topSelectDate[index]"
              :key="index"
              popper-class="content-top-select-popper"
              class="content-top-select"  size="mini" @change="$value => $emit('topSelectChange', select.name, $value)">
              <el-option
                v-for="item in select.options"
                :key="item.key || item.value"
                :value="item.value"
                :label="item.label"
              >
              </el-option>
            </el-select>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Radio from '@/components/Radio/index';

export default {
  components: { Radio },
  props: {
    type: { type: Number }, // 1-直角框 2-斜角框 3-无框
    title: { type: String, default: '' }, // 标题
    topRadioProps: {
      type: Object,
      default: () => {}
      // { // 示例
      // name: 'radio',
      //   width: 100,
      //   options: [
      //     { value: 1, label: '周' }
      //   ]
      // }
    },
    topOptionsProps: {
      type: Object,
      default: () => {}
      // { // 示例
      //   select: [
      //     {
      //       name: 'select1',
      //       options: [
      //         { value: 1, label: '111' }
      //       ]
      //     }
      //   ]
      // }
    }
  },
  data() {
    return {
      comName: '',
      iconName: '',
      topRadios: '',
      topSelectDate: []
    }
  },
  watch: {
    type: {
      handler(newVal) {
        this.comName = newVal === 1 ? 'right-angle' : newVal === 2 ? 'blique-angle' : ''
      },
      immediate: true
    }
  },
  methods: {
    topRadioChange(name, value) {
      console.log(name,value)
      this.$emit('topRadioChange', name, value);
    }
  }
}
</script>

<style lang="scss" scoped>
.content-top-wapper {
  height: 36px;
  line-height: 36px;
  .content-top-title {
    width: 100%;
    margin-left: 2.6rem;
    text-shadow: 0px 0px 6px 0px rgba(172,241,255,0.70);
  }
  .content-top {
    height: 36px;
    line-height: 36px;
    &.no-angle {
      background: url('~@/assets/contentTop/contentTopNoAngle.png') no-repeat;
    }
    &.right-angle {
      background: url('~@/assets/contentTop/contentTopRightAngleIcon.png') no-repeat;
    }
    &.blique-angle {
      background: url('~@/assets/contentTop/contentTopoBliqueAngleIcon.png') no-repeat;
      background-size: 100% 100%;
    }
  }
  .content-top-right {
    line-height: 36px;
    display: flex;
    padding: 4px 0;
    > div {
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
    .content-top-select {
      width: 65px;
      margin-right: 10px;
      line-height: 100%;
      &:last-child {
        margin: 0;
      }
      ::v-deep input{
        padding: 0 3px !important;
        font-size: 12px;
        background: transparent;
        border: 0.5px solid #4e8df9;
        border-radius: 2px;
      }
    }
  }
}
</style>

<style lang="scss">
.content-top-select-popper.el-select-dropdown {
  background: rgba(48,48,48,0.52);
  border: 1px solid rgba(78,141,249,0.72);
  border-radius: 6px;
  backdrop-filter: blur(6px);
  overflow: hidden;
  padding: 0;
  .el-select-dropdown__item {
    font-size: 12px;
    height: 22px;
    line-height: 22px;
    &.selected, &.hover {
      background: rgba(48,48,48,0.52);
    }
  }
}
</style>
